import React from 'react';

const About = () => {
  return (
    <div className="about">
      <h2>About Me</h2>
    </div>
  );
};

export default About;
