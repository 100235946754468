import React from 'react';

const Projects = () => {
  return (
    <div className="projects">
      <h2>Projects</h2>
      <ul>
      </ul>
    </div>
  );
};

export default Projects;
