import React from 'react';

const Home = () => {
  return (
    <div className="home">
      <h1>Hi, I am Amit</h1>
      <p>I am a software engineer passionate about Advanced Algorithms, System Design, Databases Internals, and Language Internals.</p>
    </div>
  );
};

export default Home;
